import { defineStore } from 'pinia'
import { teledsnAPI } from '@/services/teledsn-api'
import { Employee, EmployeeContract, EmployeeInfos, EmployeeLocality, RateHistory } from '@/models/employee.model'
import { ObjKeyValueString } from '@/models/main.model'
import { Helper } from '@/models/employee.helper'
import { useMainStore } from './main'
import { useContractStore } from './contract'
import { ContractStatus } from '@/models/contract.model'

type State = {
  employees: Employee[]
  rateHistories: RateHistory[]
  loading: boolean
  options: {
    civilities: ObjKeyValueString[]
    countries: ObjKeyValueString[]
    nationalities: ObjKeyValueString[]
  }
  employee: {
    id: number | null
    infos: EmployeeInfos
    locality: EmployeeLocality
    deletable: boolean
    contracts?: EmployeeContract[]
  }
}

export const useEmployeeStore = defineStore('employeeStore', {
  state: (): State => ({
    employees: [],
    loading: false,
    rateHistories: [],
    employee: {
      id: null,
      infos: {
        civility: '',
        birthCountry: null,
        nationality: '',
        firstname: '',
        lastname: '',
        birthdate: null,
        birthCity: '',
        socialSecurityNumber: '',
        companySerialNumber: '',
        nttNumber: null,
      },
      locality: {
        zipCode: '',
        selectedCity: null,
        selectedCountry: 'FR',
        address: {
          street: '',
          addressSupplement: '',
          namedPlace: '',
        },
      },
      contracts: [],
      deletable: false,
    },
    options: {
      civilities: [
        { key: '02', value: 'Madame' },
        { key: '01', value: 'Monsieur' },
      ],
      countries: [],
      nationalities: [],
    },
  }),
  getters: {
    hasActiveContract() {
      return (employeeId: number) => {
        const employee = this.employees.find((employee) => employee.salarieId === employeeId)
        const hasActiveContract = employee?.contrats?.results.find(
          (contrat) => contrat.statut === ContractStatus.ACTIVE,
        )
        if (hasActiveContract) return true
        return false
      }
    },
    canHaveDoubleActiveContract() {
      return (employeeId: number | null) => {
        const employee = this.employees.find((employee) => employee.salarieId === employeeId)
        if (employee && employee.peutDoublonnerContrat) return true
        return false
      }
    },
    hasTwoActiveContracts() {
      return (employeeId: number | null) => {
        const employee = this.employees.find((employee) => employee.salarieId === employeeId)
        if (employee && employee.dernierContratSyntheses?.length === 2) return true
        return false
      }
    },
    getEmployeeFromId() {
      return (employeeId: number) => {
        return this.employees.find((employee) => employee.salarieId === employeeId)
      }
    },
  },
  actions: {
    async getCompanyEmployees(companyId: number) {
      try {
        this.loading = true
        const response = await teledsnAPI.getCompanyEmployees(companyId)
        this.employees = response.map((item) => {
          return { ...item, status: item.actif ? 'en activité' : 'inactif' }
        })
      } catch (error) {
        useMainStore().addErrorIfNotAlreadyPresent({
          value: "Une erreur est survenue lors de la récupération des salariés de l'entreprise.",
        })
      } finally {
        this.loading = false
      }
    },
    async getEmployeeContracts(employeeId: number) {
      const contractStore = useContractStore()
      try {
        const employee = this.employees.find((employee) => employee.salarieId === employeeId)
        if (employee) {
          if (employee.contrats) employee.contrats.loading = true
          else employee.contrats = { results: [], loading: true }

          const response = await teledsnAPI.getEmployeeContracts(employeeId)
          response.forEach((employeeContract) => {
            employeeContract.statut = contractStore.determineContratStatus(employeeContract)
          })
          employee.contrats = { results: response, loading: false }
          this.employee.contracts = response
        }
      } catch (error) {
        useMainStore().addErrorIfNotAlreadyPresent({
          value: 'Une erreur est survenue lors de la récupération des contrats du salarié.',
        })
      }
    },
    async getEmployeeRateHistory(employeeId: number) {
      try {
        const response = await teledsnAPI.getEmployeeRateHistory(employeeId)
        this.rateHistories = response
      } catch (error) {
        useMainStore().addErrorIfNotAlreadyPresent({
          value: `Une erreur est survenue lors de la récupération de l'historique des taux du salarié.`,
        })
      }
    },
    async generateTemporarySocialNumber(companyId: number, civility: string) {
      try {
        this.loading = true
        const response = await teledsnAPI.generateTemporarySocialNumber(companyId, civility)
        this.employee.infos.nttNumber = response.numeroNtt
      } catch (error) {
        useMainStore().addErrorIfNotAlreadyPresent({
          value: 'Une erreur est survenue lors de la génération du numero NTT.',
        })
      } finally {
        this.loading = false
      }
    },
    async addEmployeeToCompany(companyId: number) {
      const mainStore = useMainStore()
      mainStore.resetServerMessages()
      try {
        this.loading = true
        const payload = Helper.constructEmployeePayload(this.employee.infos, this.employee.locality)

        const response = await teledsnAPI.addEmployeeToCompany(companyId, payload)
        this.employee.id = response.id
        mainStore.serverResponseStatut.successMessage = `L'employé a bien été ajouté à l'entreprise`
      } catch (error) {
        mainStore.setServerResponseFromError(error, true)
      } finally {
        this.loading = false
      }
    },
    async modifyEmployeeInfos(employeeId: number) {
      const mainStore = useMainStore()
      try {
        this.loading = true
        const payload = Helper.constructEmployeePayload(this.employee.infos, this.employee.locality)

        await teledsnAPI.modifyEmployeeInfos(employeeId, payload)
        mainStore.serverResponseStatut.successMessage = `L'employé a bien été modifié`
        mainStore.formHaveBeenModified = false
      } catch (error) {
        mainStore.setServerResponseFromError(error, true)
      } finally {
        this.loading = false
      }
    },
    async getEmployeeInfos(employeeId: number) {
      try {
        this.loading = true
        const response = await teledsnAPI.getEmployeeInfos(employeeId)

        this.employee.infos = Helper.storingEmployeeInfosFromAPI(response)
        this.employee.locality = Helper.storingEmployeeLocalityFromAPI(response)
      } catch (error) {
        useMainStore().addErrorIfNotAlreadyPresent({
          value: 'Une erreur est survenue lors de la récupération des informations du salarié.',
        })
      } finally {
        this.loading = false
      }
    },
    async deleteEmployee(employeeId: number) {
      const mainStore = useMainStore()
      try {
        this.loading = true
        await teledsnAPI.deleteEmployee(employeeId)
        mainStore.serverResponseStatut.successMessage = 'Le salarié a bien été supprimé'
      } catch (error) {
        mainStore.setServerResponseFromError(error)
      } finally {
        this.loading = false
      }
    },
    async getCountriesOptions() {
      try {
        const response = await teledsnAPI.getCountriesOptions()
        this.options.countries = response.map((country) => {
          if (country.key === '') {
            return { key: 'FR', value: 'FRANCE' }
          }
          return country
        })
        //Ajouter la France en tête de liste en plus
        this.options.countries.unshift({ key: 'FR', value: 'FRANCE' })
      } catch (error) {
        useMainStore().addErrorIfNotAlreadyPresent({
          value: 'Une erreur est survenue lors de la récupération des pays.',
        })
      }
    },
    async getNationalitiesOptions() {
      try {
        const response = await teledsnAPI.getNationalitiesOptions()
        this.options.nationalities = response
      } catch (error) {
        useMainStore().addErrorIfNotAlreadyPresent({
          value: 'Une erreur est survenue lors de la récupération des nationalités.',
        })
      }
    },
    setEmployee(employeeFromList: Employee) {
      this.employee = Helper.storingEmployee(employeeFromList)
    },
  },
  persist: {
    afterRestore: (ctx) => {
      if (ctx.pinia.state?.value?.employeeStore?.employee?.infos?.birthdate) {
        ctx.pinia.state.value.employeeStore.employee.infos.birthdate = new Date(
          ctx.pinia.state.value.employeeStore.employee.infos.birthdate,
        )
      }
    },
  },
})
