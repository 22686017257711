export const validateEmailFormat = (email: string) => {
  if (!email) return false
  const regex = new RegExp(
    `([!#-'*+/-9=?A-Z^-~-]+(.[!#-'*+/-9=?A-Z^-~-]+)*|"([]!#-[^-~ \t]|(\\[\t -~]))+")@([!#-'*+/-9=?A-Z^-~-]+(.[!#-'*+/-9=?A-Z^-~-]+)*|[[\t -Z^-~]*])`,
  )
  return regex.test(email)
}

export const determineMonthLabelPeriod = (period: string) => {
  if (!period || period === '' || !period.includes('/')) return period
  const month = period.substring(0, 2)
  const year = period.substring(3, 7)
  let monthLabel = ''

  switch (month) {
    case '01':
      monthLabel = 'Janvier'
      break
    case '02':
      monthLabel = 'Février'
      break
    case '03':
      monthLabel = 'Mars'
      break
    case '04':
      monthLabel = 'Avril'
      break
    case '05':
      monthLabel = 'Mai'
      break
    case '06':
      monthLabel = 'Juin'
      break
    case '07':
      monthLabel = 'Juillet'
      break
    case '08':
      monthLabel = 'Août'
      break
    case '09':
      monthLabel = 'Septembre'
      break
    case '10':
      monthLabel = 'Octobre'
      break
    case '11':
      monthLabel = 'Novembre'
      break
    case '12':
      monthLabel = 'Décembre'
      break
  }

  return monthLabel + ' ' + year
}

export const beautifySocialNumber = (socialNumber: string): string => {
  return (
    socialNumber.substring(0, 1) +
    ' ' +
    socialNumber.substring(1, 3) +
    ' ' +
    socialNumber.substring(3, 5) +
    ' ' +
    socialNumber.substring(5, 7) +
    ' ' +
    socialNumber.substring(7, 10) +
    ' ' +
    socialNumber.substring(10, 13) +
    ' ' +
    socialNumber.substring(13, socialNumber.length)
  )
}

export const getElementPosition = (el: Element): { top: number; left: number } => {
  if (!el) {
    return { top: 0, left: 0 }
  }
  const rect = el.getBoundingClientRect(),
    scrollLeft = window.scrollX || document.documentElement.scrollLeft,
    scrollTop = window.scrollY || document.documentElement.scrollTop
  return { top: rect.top + scrollTop + 45, left: rect.left + scrollLeft }
}

export const generateYearsBetween = (startYear?: number, endYear?: number): number[] => {
  const endDate = endYear || new Date().getFullYear()
  let startDate = startYear || new Date().getFullYear() - 4

  const years = []

  for (let i = startDate; i <= endDate; i++) {
    years.push(startDate)
    startDate++
  }
  return years.reverse()
}

export const getStringMapContent = (key?: string, map?: Map<string, string[]>) => {
  if (key && map && map instanceof Map) {
    return map.get(key) ?? []
  }

  return []
}

export const convertRecordToMap = (data?: Record<string, string[]>): Map<string, string[]> => {
  const map = new Map<string, string[]>()
  if (data) {
    Object.entries<string[]>(data).forEach(([key, value]) => {
      map.set(key, value)
    })
  }
  return map
}
