<script setup lang="ts">
import { useMainStore } from '@/stores/main'
import { computed } from 'vue'

type Props = {
  additionalMessages?: string[]
}

const props = defineProps<Props>()

const mainStore = useMainStore()

const blockingErrors = computed(() => mainStore.getBlockingServorErrors)
const nonBlockingErrors = computed(() => mainStore.getNonBlockingServorErrors)
</script>
<template>
  <Message v-if="blockingErrors && blockingErrors.length > 0" severity="error" :closable="false" class="m-auto mb-4">
    <template #messageicon>
      <i class="p-icon p-message-icon pi pi-exclamation-circle hidden md:flex" />
    </template>
    <p>Le formulaire comporte des erreurs :</p>
    <ul>
      <li v-for="errorMessage in blockingErrors" :key="errorMessage">{{ errorMessage }}</li>
      <li v-for="additionalMessage in props.additionalMessages" :key="additionalMessage">{{ additionalMessage }}</li>
      <li v-if="blockingErrors.includes('Siret déjà existant')">
        Ce numéro de siret a déjà été utilisé. Veuillez vérifier sa présence dans la liste de vos entreprises.
      </li>
    </ul>
  </Message>
  <Message
    v-if="nonBlockingErrors && nonBlockingErrors.length > 0"
    severity="warn"
    :closable="false"
    class="m-auto mb-4"
  >
    <template #messageicon>
      <i class="p-icon p-message-icon pi pi-exclamation-triangle hidden md:flex" />
    </template>
    <p>Le formulaire comporte des erreurs non bloquantes :</p>
    <ul>
      <li v-for="errorMessage in nonBlockingErrors" :key="errorMessage">{{ errorMessage }}</li>
      <li v-if="nonBlockingErrors.includes('Siret déjà existant')">
        Ce numéro de siret a déjà été utilisé. Veuillez vérifier sa présence dans la liste de vos entreprises.
      </li>
    </ul>
  </Message>
</template>
