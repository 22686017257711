import { CitiesSuggestion } from './company.model'

export type MainError = {
  value: string
  blocking?: boolean
  keepAfterDisplay?: boolean
  alreadyDisplayed?: boolean
}

export type Subscriptions = {
  companyId: number
  companyName: string
  expirationDate: Date
  firstSubscriptionDate: Date
  siret: string
  status: { code: string; label: string }
}

export type SubscriptionResponse = {
  entreprise_id: number
  raison_sociale: string
  date_expiration: string
  premier_abonnement: string
  siret: string
  status: { code: string; label: string }
}

export type Invoices = {
  companyId: number
  subscriptionId: number
  subscriptionOrRenewingDate: Date
  paymentDate: Date | null
  companyName: string
  paymentType: string
}

export type InvoicesResponse = {
  entreprise_id: number
  abo_id: number
  date_abo_ou_renouvellement: string
  date_reglement: string
  raison_sociale: string
  type_paiement: string
}

export enum STATUS_COMPANY {
  ACTIVE = 'Active',
  NOT_SUBSCRIBED = 'Non abonné',
  CREATION_IN_PROGRESS = 'En cours de création',
}

export type Companies = {
  id: number
  name: string
  siret: string
  status?: STATUS_COMPANY
  deletable?: boolean
  isSubscriptionActive?: boolean
}

export type CompaniesResponse = {
  entreprise_id: number
  raison_sociale: string
  siret: string
  status?: boolean
  supprimable?: boolean
  abonnementActif?: boolean
}

export type ErrorDsn = {
  response: {
    data: {
      functionalErrors: {
        message: string
        blocant?: boolean
        propertyNames: string[]
      }[]
      fieldErrors: {
        message: string
      }[]
      message: string
    }
  }
}

export type ObjKeyValueString = {
  key: string
  value: string
}

export type Subscription = {
  awaitingPaymentWithoutCollection: boolean
  cbProvider: string
  companyBillingInfos: {
    name: string
    street: string
    zipCode: string
    locality: string
    country: string
  }
  invoiceView: {
    title: string
    duration: number
    amountIncludingAllTaxes: number
    amountWithoutTax: number
    taxAmount: number
    taxRate: number
    totalCentsTtc: number
    paymentType: string
    dateInvoice: string
  }
  braintree: {
    clientToken: string
  }
}

export type SubscriptionInfoResponse = {
  existeAttentePaiementSansEncaissement: boolean
  cbProvider: string
  facturationEntreprise: {
    nom: string
    voie: string
    codePostal: string
    localite: string
    pays: string
  }
  factureView: {
    titre: string
    duree: number
    totalHt: number
    montantTva: number
    tauxTva: number
    totalTtc: number
    totalCentimesTtc: number
    typePaiement: string
    dateFacture: string
  }
  braintree: {
    clientToken: string
  }
}

export type SubscriptionServicePaymentPayload = {
  cbProvider?: string
  nomFacture: string
  addressStreet: string
  complementAdresse: string
  addressPostalCode: string
  addressCity: CitiesSuggestion | string | null
  addressCountry: string
  numeroTva?: string
  paymentType: string
  firstName?: string
  lastName?: string
  braintreeNonce?: any
  braintreeDeviceData?: string
  payplugToken?: string
}

export type ConfirmPaymentResponse = {
  paymentType: string
  estPaiementDiffere: boolean
  raisonSociale: string
  montantTtc: number
  factureTotalHt: number
  abonnementId: number
  dsnId: number
}

export type SupportMessagePayload = {
  feedbackEmail: string
  feedbackObjet: string
  feedbackRequete: string
  feedbackTelephone: string | null
  entrepriseId: number
}

export type OrgPaymentColumnTab = {
  label: string
  subcolumns: OrgPaymentSubColumnTab[]
}

export type OrgPaymentSubColumnTab = {
  identifiantOrganisme: string
  label: string
  libelleOrganisme: string
}

export type OrgPaymentRowTab = {
  label: string
  dernierEnvoiId: number | null
  dsnId: number
  data: OrgPaymentRowData[]
}

export type OrgPaymentRowData = {
  periode: string
  versementMensuel: number
  cumulVersemenTrimestriel: number
  modePaiement: string
  afficheTotalPaiementTrimestre: boolean
  codeOrganisme: string
  libelleOrganisme: string
  identifiantOrganisme: string
  estMensualise: boolean
  affichable: boolean
  montantPersonnalise: number
  refChqVir: string
}

export type SalaryRecap = {
  employee: { firstName: string; lastName: string }
  paiements: Payment[]
}

export type Payment = {
  label: string
  total: number
  january: number | null
  february: number | null
  march: number | null
  april: number | null
  may: number | null
  june: number | null
  july: number | null
  august: number | null
  september: number | null
  october: number | null
  november: number | null
  december: number | null
}

export type ThreeDSecurePayload = {
  threeDSecure: {
    amount: number
    email: string
    billingAddress: {
      givenName: string
      surname: string
      phoneNumber: string
      streetAddress: string
      extendedAddress: string
      locality: string
      region: string
      postalCode: string
      countryCodeAlpha2: string
    }
  }
}

export type BraintreePayload = {
  nonce: string
  default?: boolean | undefined
  details?: {
    cardholderName: string | undefined
    expirationMonth: string | undefined
    expirationYear: string | undefined
    bin: string | undefined
    cardType: string | undefined
    lastFour: string | undefined
    lastTwo: string | undefined
  }
  type?:
    | 'AndroidPayCard'
    | 'ApplePayCard'
    | 'CreditCard'
    | 'MasterpassCard'
    | 'PayPalAccount'
    | 'UsBankAccount'
    | 'VenmoAccount'
    | 'VisaCheckoutCard'
    | 'SamsungPayCard'
    | undefined
  threeDSecureInfo?: {
    enrolled: string
    liabilityShiftPossible: boolean
    liabilityShifted: boolean
    status: string
  }
  binData?: {
    prepaid: 'Yes' | 'No' | 'Unknown'
    healthcare: 'Yes' | 'No' | 'Unknown'
    debit: 'Yes' | 'No' | 'Unknown'
    durbinRegulated: 'Yes' | 'No' | 'Unknown'
    commercial: 'Yes' | 'No' | 'Unknown'
    payroll: 'Yes' | 'No' | 'Unknown'
    issuingBank: 'Yes' | 'No' | 'Unknown'
    countryOfIssuance: 'Yes' | 'No' | 'Unknown'
    productId: 'Yes' | 'No' | 'Unknown'
  }
}

export type BreadcrumbItem = {
  label: string
  icon?: string
  route?: string
  havePersonalData?: boolean
}
