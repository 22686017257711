<script setup lang="ts">
import { useEmployeeStore } from '@/stores/employee'
import { useVModel } from '@vueuse/core'
import { router } from '@/routes'
import { Router } from '@/models/router.model'
import SelectEmployeeDropdown from '../employee/SelectEmployeeDropdown.vue'
import { computed, ref, watch } from 'vue'
import { useContractStore } from '@/stores/contract'
import { useRoute } from 'vue-router'

type Props = {
  visible: boolean
}

const props = defineProps<Props>()
const emit = defineEmits(['update:visible'])

const route = useRoute()
const employeeStore = useEmployeeStore()
const contractStore = useContractStore()
const visible = useVModel(props, 'visible', emit)

const employee = computed(() => employeeStore.employee)
const haveEmployees = computed(() => employeeStore.employees.length > 0)
const canHaveDoubleActiveContract = computed(() => employeeStore.canHaveDoubleActiveContract(employee.value.id))
const hasTwoActiveContracts = computed(() => employeeStore.hasTwoActiveContracts(employee.value.id))

const showActiveContractWarning = ref(false)

watch(employee.value, () => {
  if (employee.value.id) {
    showActiveContractWarning.value =
      hasTwoActiveContracts.value ||
      (employeeStore.hasActiveContract(employee.value.id) && !canHaveDoubleActiveContract.value)
  } else {
    showActiveContractWarning.value = false
  }
})

const goToAddContract = () => {
  if (route.path !== Router.CONTRACT + Router.STEP_GENERAL_INFORMATIONS) {
    contractStore.$reset()
  }
  router.push(Router.EMPLOYEE + Router.CONTRACT + Router.STEP_GENERAL_INFORMATIONS)
  visible.value = false
}

const goToEmployeePage = () => {
  visible.value = false
  router.push(Router.EMPLOYEE)
}
</script>

<template>
  <Dialog v-model:visible="visible" header="Ajouter un contrat" modal>
    <SelectEmployeeDropdown
      v-if="haveEmployees"
      placeholder="Veuillez sélectionner un salarié auquel rattacher un contrat"
      label="Sélectionner un salarié"
      class="mt-4"
    />
    <Message v-else :closable="false">
      Vous n'avez pas de salarié dans l'entreprise. Voulez-vous ajouter un salarié ?
    </Message>
    <InlineMessage v-if="showActiveContractWarning" severity="warn" class="mt-5">
      <span v-if="hasTwoActiveContracts">
        Le salarié a déjà 2 contrats actifs, veuillez clôturer au moins un contrat actif pour en ajouter un nouveau.
      </span>
      <span v-else>
        Le dernier contrat est toujours actif. Pour ajouter un nouveau contrat, veuillez mettre une date de fin au
        contrat actif pour le clôturer. Si vous voulez avoir deux contrats actifs pour un salarié, veuillez contacter le
        support pour permettre le doublon de contrat à un salarié.
      </span>
    </InlineMessage>
    <template #footer>
      <Button @click="visible = false" label="Retour" severity="secondary" rounded class="p-button-sm-mobile" />
      <Button
        v-if="employee.id && (!canHaveDoubleActiveContract || hasTwoActiveContracts)"
        @click="goToEmployeePage"
        label="Voir fiche salarié"
        icon="pi pi-eye"
        rounded
        class="p-button-sm-mobile"
      />
      <Button
        v-if="haveEmployees"
        @click="goToAddContract()"
        label="Ajouter un contrat"
        icon="pi pi-plus"
        rounded
        :disabled="
          employeeStore.employee.id === null ||
          (employeeStore.hasActiveContract(employee.id!) && (!canHaveDoubleActiveContract || hasTwoActiveContracts))
        "
        class="p-button-sm-mobile"
      />
      <Button
        v-else
        @click="goToEmployeePage"
        label="Ajouter un salarié"
        icon="pi pi-plus"
        rounded
        class="p-button-sm-mobile"
      />
    </template>
  </Dialog>
</template>
