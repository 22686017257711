import { defineStore } from 'pinia'
import {
  WorkStoppage,
  EndingContract,
  EndingContractReason,
  ENDING_CONTRACT_REASON,
  NOTICE_TYPE,
  LastSendingReportDetails,
} from '@/models/reporting.model'
import { teledsnAPI } from '@/services/teledsn-api'
import { useMainStore } from './main'
import { useEmployeeStore } from './employee'
import { useCompanyStore } from './company'
import { ObjKeyValueString } from '@/models/main.model'
import { router } from '@/routes'
import { Router } from '@/models/router.model'
import { Helper } from '@/models/reporting.helper'
import { DECLARATION_TYPE, ReportDashboard, STATUS } from '@/models/declaration.model'

type State = {
  loading: boolean
  report: ReportDashboard
  workStoppage: WorkStoppage
  endingContract: EndingContract
  lastSendingReportDetails: LastSendingReportDetails | null
  options: {
    workingStoppageReasonOptions: ObjKeyValueString[]
    resumingOptions: ObjKeyValueString[]
    endingContractReasons: EndingContractReason[]
    noticeOptions: ObjKeyValueString[]
    specialStatusOptions: ObjKeyValueString[]
  }
  form: { isSubmitted: boolean; haveEmptyFields: boolean; isEditable: boolean }
}

export const useReportingStore = defineStore('reportingStore', {
  state: (): State => ({
    loading: false,
    report: {
      id: null,
      status: STATUS.TO_COMPLETE,
      statusLabel: '',
      period: new Date(),
      rawPeriod: '',
      nature: DECLARATION_TYPE.INCONNU,
      sendingStatus: null,
      name: '',
      firstName: '',
      exigibilityLabel: '',
      editable: false,
      lastSendingDate: null,
      lastSendingId: null,
      resent: false,
    },
    lastSendingReportDetails: null,
    workStoppage: {
      startOfWorkStoppage: null,
      resumingDate: null,
      reasonOfWorkStoppage: '',
      resumingReason: '',
      accidentDate: null,
      subrogation: false,
      subrogationStartDate: null,
      subrogationEndDate: null,
      employerIBAN: '',
      employerBIC: '',
      collectorSiret: '',
    },
    endingContract: {
      contractId: null,
      complementaryInputs: [],
      generalInformations: {
        isAnticipatedDsnNecessary: false,
        mustDoFranceTravailProcess: false,
        endingDate: null,
        endingReason: null,
        lastDayWork: null,
        firingDate: null,
        notificationDate: null,
        conventionDate: null,
      },
      complementaryInfos: {
        notice: {
          noticeType: '',
          noticeStartDate: null,
          noticeEndDate: null,
        },
        specialStatus: null,
        transaction: false,
        pensionPortability: false,
      },
      lastMonthRevenue: {
        untaxedGrossIncome: null,
        netTax: null,
        netPayable: null,
        hoursWorked: null,
        unemploymentInsuranceGross: null,
        unusedHoursDIF: null,
        noticeDuration: null,
        compensationAmount: null,
        netHourlyWages: null,
        daysWorkedNumberTooltip: '',
      },
    },
    options: {
      workingStoppageReasonOptions: [],
      resumingOptions: [],
      endingContractReasons: [],
      noticeOptions: [],
      specialStatusOptions: [],
    },
    form: { isSubmitted: false, haveEmptyFields: false, isEditable: false },
  }),
  getters: {
    isFirstStep() {
      return router.currentRoute.value.meta.isFirstStep
    },
    isLastStep() {
      return router.currentRoute.value.meta.isLastStep
    },
    isEditable() {
      return () => {
        if (this.report.id && this.report.status === STATUS.ON_GOING) {
          return false
        } else if (this.report.id && this.lastSendingReportDetails?.ouvrable) {
          return true
        } else if (this.report.id) {
          return this.form.isEditable
        }
        return true
      }
    },
  },
  actions: {
    async resetEmployeeAndReportState() {
      const employeeStore = useEmployeeStore()
      const reportingStore = useReportingStore()
      const companyStore = useCompanyStore()

      employeeStore.$reset()
      reportingStore.$reset()

      if (companyStore.company.id) await employeeStore.getCompanyEmployees(companyStore.company.id)
    },
    getEmployerBankInfos() {
      const companyStore = useCompanyStore()
      this.workStoppage.collectorSiret =
        companyStore.company.generalInformations.companyInfos.siren +
        companyStore.company.generalInformations.companyInfos.nic
      this.workStoppage.employerBIC = companyStore.company.socialOrganizations.rib.bic!
      this.workStoppage.employerIBAN = companyStore.company.socialOrganizations.rib.iban!
    },
    previousStep() {
      let previousStep = ''
      switch (router.currentRoute.value.name) {
        case Router.REPORT_ENDING_CONTRACT:
          previousStep = Router.DASHBOARD
          break
        case Router.LAST_MONTH_REVENUE:
          previousStep = Router.REPORT_ENDING_CONTRACT
          break
        case Router.STEP_ADDITIONAL_INFORMATIONS:
          previousStep = Router.REPORT_ENDING_CONTRACT + Router.LAST_MONTH_REVENUE
          break
        default:
          previousStep = '/'
      }

      router.push(previousStep)
    },
    async nextStep() {
      const mainStore = useMainStore()
      let nextStep = ''
      this.form.isSubmitted = true

      if (!this.form.haveEmptyFields || !this.isEditable()) {
        switch (router.currentRoute.value.fullPath) {
          case Router.REPORT_ENDING_CONTRACT:
            nextStep = Router.REPORT_ENDING_CONTRACT + Router.LAST_MONTH_REVENUE
            break
          case Router.REPORT_ENDING_CONTRACT + Router.LAST_MONTH_REVENUE:
            nextStep = Router.REPORT_ENDING_CONTRACT + Router.STEP_ADDITIONAL_INFORMATIONS
            break
          case Router.REPORT_ENDING_CONTRACT + Router.STEP_ADDITIONAL_INFORMATIONS:
            nextStep = Router.DASHBOARD + Router.REPORTS
            if (this.report.id) await this.modifyReportEndingContract()
            else await this.reportEndingContract()
            break
          default:
            nextStep = Router.DASHBOARD + Router.REPORTS
        }
      } else {
        mainStore.addErrorIfNotAlreadyPresent({
          value: 'Un ou plusieurs champs sont manquants',
          keepAfterDisplay: true,
        })
      }

      if (!mainStore.serverResponseStatut.errorMessages || mainStore.serverResponseStatut.errorMessages.length === 0) {
        router.push(nextStep)
      }
    },
    async getEndingContractReasons() {
      try {
        const response = await teledsnAPI.getEndingContractReasons(this.endingContract.contractId!)
        this.options.endingContractReasons = response
      } catch (error) {
        useMainStore().addErrorIfNotAlreadyPresent({
          value: 'Une erreur est survenue lors de la récupération des options de motif de fin de contrat.',
        })
      }
    },
    async deleteReportEndingContract() {
      const mainStore = useMainStore()

      try {
        this.loading = true
        await teledsnAPI.deleteReportEndingContract(this.report.id!)
        mainStore.serverResponseStatut.successMessage =
          'Le signalement de fin de contrat de travail a bien été supprimé'
      } catch (error) {
        mainStore.setServerResponseFromError(error)
      } finally {
        this.loading = false
      }
    },
    async getResumingOptions() {
      try {
        const response = await teledsnAPI.getResumingOptions()
        this.options.resumingOptions = response
      } catch (error) {
        useMainStore().addErrorIfNotAlreadyPresent({
          value: 'Une erreur est survenue lors de la récupération des options de reprise de travail.',
        })
      }
    },
    async getNoticeOptions() {
      try {
        const response = await teledsnAPI.getNoticeOptions()
        this.options.noticeOptions = response
      } catch (error) {
        useMainStore().addErrorIfNotAlreadyPresent({
          value: 'Une erreur est survenue lors de la récupération des options de préavis.',
        })
      }
    },
    async getSpecialStatusOptions() {
      try {
        const response = await teledsnAPI.getSpecialStatusOptions()
        this.options.specialStatusOptions = response
      } catch (error) {
        useMainStore().addErrorIfNotAlreadyPresent({
          value: 'Une erreur est survenue lors de la récupération des options de statut particulier.',
        })
      }
    },
    async getWorkingStoppageReasonOptions() {
      try {
        const response = await teledsnAPI.getWorkingStoppageReasonOptions()
        this.options.workingStoppageReasonOptions = response
      } catch (error) {
        useMainStore().addErrorIfNotAlreadyPresent({
          value: "Une erreur est survenue lors de la récupération des options des motifs d'arrêt.",
        })
      }
    },
    async checkAnticipatedDsnPresence(endingDateContract: string) {
      const mainStore = useMainStore()

      try {
        this.loading = true
        const response = await teledsnAPI.checkAnticipatedDsnPresence(
          endingDateContract,
          this.endingContract.contractId!,
        )
        this.endingContract.generalInformations.isAnticipatedDsnNecessary = !response.dejaEnvoyeDsnMensuelleAnticipee
        this.endingContract.generalInformations.mustDoFranceTravailProcess = response.doitFaireProcedureFranceTravail
      } catch (error) {
        mainStore.setServerResponseFromError(error)
      } finally {
        this.loading = false
      }
    },
    async getHourlyWagesAndDaysWorkedAndComplementaryInputs() {
      const mainStore = useMainStore()
      try {
        const response = await teledsnAPI.getHourlyWagesAndDaysWorkedAndComplementaryInputs(
          this.endingContract.contractId!,
          this.endingContract.generalInformations.endingDate!,
          this.endingContract.generalInformations.endingReason!,
        )
        this.endingContract.lastMonthRevenue.netHourlyWages = response.salaireHoraireNet
        this.endingContract.lastMonthRevenue.daysWorkedNumberTooltip = response.messageSalaireHoraireNet
        this.endingContract.complementaryInputs = response.champsComplementaires
      } catch (error) {
        mainStore.setServerResponseFromError(error)
      }
    },
    async reportWorkStoppage(isResuming: boolean) {
      const mainStore = useMainStore()
      const employeeStore = useEmployeeStore()
      const companyStore = useCompanyStore()

      try {
        this.loading = true
        const constructPayload = Helper.constructReportWorkStoppagePayload(this.workStoppage, isResuming)

        if (companyStore.company.id && employeeStore.employee.id) {
          const payload = {
            entrepriseId: companyStore.company.id,
            salarieId: employeeStore.employee.id,
            ...constructPayload,
          }
          await teledsnAPI.reportWorkStoppage(payload)
          mainStore.serverResponseStatut.successMessage = `Le signalement a bien été effectué.`
        }
      } catch (error) {
        mainStore.setServerResponseFromError(error)
      } finally {
        this.loading = false
      }
    },

    async modifyReportWorkStoppageInfos(isResuming: boolean) {
      const mainStore = useMainStore()
      const employeeStore = useEmployeeStore()
      const companyStore = useCompanyStore()

      try {
        this.loading = true

        if (companyStore.company.id && employeeStore.employee.id) {
          const constructPayload = Helper.constructReportWorkStoppagePayload(this.workStoppage, isResuming)
          const payload = {
            entrepriseId: companyStore.company.id,
            salarieId: employeeStore.employee.id,
            ...constructPayload,
          }
          await teledsnAPI.modifyReportWorkStoppageInfos(this.report.id!, payload)
          mainStore.serverResponseStatut.successMessage = `Le signalement a bien été enregistré.`
        }
      } catch (error) {
        mainStore.setServerResponseFromError(error, true)
      } finally {
        this.loading = false
      }
    },

    async deleteReportWorkStoppage() {
      const mainStore = useMainStore()

      try {
        this.loading = true
        await teledsnAPI.deleteReportWorkStoppage(this.report.id!)
        mainStore.serverResponseStatut.successMessage = "Le signalement d'arrêt/reprise de travail a bien été supprimé"
      } catch (error) {
        mainStore.setServerResponseFromError(error)
      } finally {
        this.loading = false
      }
    },

    async reportEndingContract() {
      const mainStore = useMainStore()
      const employeeStore = useEmployeeStore()
      const companyStore = useCompanyStore()

      try {
        this.loading = true

        if (companyStore.company.id && employeeStore.employee.id) {
          const constructPayload = Helper.constructReportEndingContractPayload(
            this.endingContract.generalInformations,
            this.endingContract.lastMonthRevenue,
            this.endingContract.complementaryInfos,
          )
          const payload = {
            entrepriseId: companyStore.company.id,
            salarieId: employeeStore.employee.id,
            contratId: this.endingContract.contractId!,
            ...constructPayload,
          }
          await teledsnAPI.reportEndingContract(payload)
          mainStore.serverResponseStatut.successMessage = `Le signalement de fin de contrat de travail a bien été effectué.`
        }
      } catch (error) {
        mainStore.setServerResponseFromError(error, true)
      } finally {
        this.loading = false
      }
    },
    async modifyReportEndingContract() {
      const mainStore = useMainStore()
      const employeeStore = useEmployeeStore()
      const companyStore = useCompanyStore()

      try {
        this.loading = true

        if (companyStore.company.id && employeeStore.employee.id) {
          const constructPayload = Helper.constructReportEndingContractPayload(
            this.endingContract.generalInformations,
            this.endingContract.lastMonthRevenue,
            this.endingContract.complementaryInfos,
          )
          const payload = {
            entrepriseId: companyStore.company.id,
            salarieId: employeeStore.employee.id,
            contratId: this.endingContract.contractId!,
            ...constructPayload,
          }
          await teledsnAPI.modifyReportEndingContract(payload, this.report.id!)
          mainStore.serverResponseStatut.successMessage = `Le signalement de fin de contrat de travail a bien été modifié.`
        }
      } catch (error) {
        mainStore.setServerResponseFromError(error, true)
      } finally {
        this.loading = false
      }
    },
    setDefaultNoticeTypesAndOptions() {
      switch (this.endingContract.generalInformations.endingReason!) {
        case ENDING_CONTRACT_REASON.RUPTURE_MOTIF_ECO_CSP:
          this.endingContract.complementaryInfos.notice.noticeType = NOTICE_TYPE.NOTICE_NOT_GIVEN_NOT_PAID_CSP
          this.options.noticeOptions = this.options.noticeOptions.filter(
            (option) => option.key === NOTICE_TYPE.NOTICE_NOT_GIVEN_NOT_PAID_CSP,
          )
          break
        case ENDING_CONTRACT_REASON.FIN_PERIODE_ESSAI_INIT_EMPLOYEUR:
        case ENDING_CONTRACT_REASON.FIN_PERIODE_ESSAI_INIT_SALARIE:
        case ENDING_CONTRACT_REASON.RUPTURE_CONVENTIONNELLE:
          this.endingContract.complementaryInfos.notice.noticeType = NOTICE_TYPE.NO_APPLICABLE_NOTICE
          this.options.noticeOptions = this.options.noticeOptions.filter(
            (option) => option.key === NOTICE_TYPE.NO_APPLICABLE_NOTICE,
          )
          break
      }
      if (!this.endingContract.complementaryInfos.notice.noticeType) {
        this.endingContract.complementaryInfos.notice.noticeType = NOTICE_TYPE.NO_APPLICABLE_NOTICE
      }
    },
    async getReportEndingContractInfos() {
      try {
        const response = await teledsnAPI.getReportEndingContractInfos(this.report.id!)
        this.endingContract = Helper.storingDataFromAPI(response.informationsGenerales)
        this.lastSendingReportDetails = response.detailDernierEnvoi
        useEmployeeStore().employee.id = response.informationsGenerales.salarieId!
      } catch (error) {
        useMainStore().addErrorIfNotAlreadyPresent({
          value: 'Une erreur est survenue lors de la récupération des infos du signalement.',
        })
      }
    },
    async getReportWorkStoppageInfos() {
      try {
        const response = await teledsnAPI.getReportWorkStoppageInfos(this.report.id!)
        this.workStoppage = Helper.storingWorkStoppageDataFromAPI(response.informationsGenerales)
        this.lastSendingReportDetails = response.detailDernierEnvoi
        useEmployeeStore().employee.id = response.informationsGenerales.salarieId!
      } catch (error) {
        useMainStore().addErrorIfNotAlreadyPresent({
          value: 'Une erreur est survenue lors de la récupération des infos du signalement.',
        })
      }
    },
    async sendWorkingStoppageReport() {
      const mainStore = useMainStore()
      this.loading = true
      try {
        await teledsnAPI.sendWorkingStoppageReport(this.report.id!)
        mainStore.serverResponseStatut.successMessage = 'Le signalement a bien été envoyé'
      } catch (error) {
        mainStore.setServerResponseFromError(error)
      } finally {
        this.loading = false
      }
    },
    async sendEndingContractReport() {
      const mainStore = useMainStore()
      this.loading = true
      try {
        await teledsnAPI.sendEndingContractReport(this.report.id!)
        mainStore.serverResponseStatut.successMessage = 'Le signalement de fin de contrat a bien été envoyé'
      } catch (error) {
        mainStore.setServerResponseFromError(error)
      } finally {
        this.loading = false
      }
    },
  },
  persist: {
    afterRestore: (ctx) => {
      if (ctx.pinia.state?.value?.reportingStore?.workStoppage?.startOfWorkStoppage) {
        ctx.pinia.state.value.reportingStore.workStoppage.startOfWorkStoppage = new Date(
          ctx.pinia.state.value.reportingStore.workStoppage.startOfWorkStoppage,
        )
      }
      if (ctx.pinia.state?.value?.reportingStore?.workStoppage?.resumingDate) {
        ctx.pinia.state.value.reportingStore.workStoppage.resumingDate = new Date(
          ctx.pinia.state.value.reportingStore.workStoppage.resumingDate,
        )
      }
      if (ctx.pinia.state?.value?.reportingStore?.workStoppage?.accidentDate) {
        ctx.pinia.state.value.reportingStore.workStoppage.accidentDate = new Date(
          ctx.pinia.state.value.reportingStore.workStoppage.accidentDate,
        )
      }
      if (ctx.pinia.state?.value?.reportingStore?.workStoppage?.subrogationStartDate) {
        ctx.pinia.state.value.reportingStore.workStoppage.subrogationStartDate = new Date(
          ctx.pinia.state.value.reportingStore.workStoppage.subrogationStartDate,
        )
      }
      if (ctx.pinia.state?.value?.reportingStore?.workStoppage?.subrogationEndDate) {
        ctx.pinia.state.value.reportingStore.workStoppage.subrogationEndDate = new Date(
          ctx.pinia.state.value.reportingStore.workStoppage.subrogationEndDate,
        )
      }
      if (ctx.pinia.state?.value.reportingStore?.endingContract?.generalInformations?.endingDate) {
        ctx.pinia.state.value.reportingStore.endingContract.generalInformations.endingDate = new Date(
          ctx.pinia.state.value.reportingStore.endingContract.generalInformations.endingDate,
        )
      }
      if (ctx.pinia.state?.value.reportingStore?.endingContract?.generalInformations?.lastDayWork) {
        ctx.pinia.state.value.reportingStore.endingContract.generalInformations.lastDayWork = new Date(
          ctx.pinia.state.value.reportingStore.endingContract.generalInformations.lastDayWork,
        )
      }
      if (ctx.pinia.state?.value.reportingStore?.endingContract?.generalInformations?.firingDate) {
        ctx.pinia.state.value.reportingStore.endingContract.generalInformations.firingDate = new Date(
          ctx.pinia.state.value.reportingStore.endingContract.generalInformations.firingDate,
        )
      }
      if (ctx.pinia.state?.value.reportingStore?.endingContract?.generalInformations?.conventionDate) {
        ctx.pinia.state.value.reportingStore.endingContract.generalInformations.conventionDate = new Date(
          ctx.pinia.state.value.reportingStore.endingContract.generalInformations.conventionDate,
        )
      }
      if (ctx.pinia.state?.value.reportingStore?.endingContract?.generalInformations?.notificationDate) {
        ctx.pinia.state.value.reportingStore.endingContract.generalInformations.notificationDate = new Date(
          ctx.pinia.state.value.reportingStore.endingContract.generalInformations.notificationDate,
        )
      }
      if (ctx.pinia.state?.value.reportingStore?.endingContract?.complementaryInfos?.notice?.noticeStartDate) {
        ctx.pinia.state.value.reportingStore.endingContract.complementaryInfos.notice.noticeStartDate = new Date(
          ctx.pinia.state.value.reportingStore.endingContract.complementaryInfos.notice.noticeStartDate,
        )
      }
      if (ctx.pinia.state?.value.reportingStore?.endingContract?.complementaryInfos?.notice?.noticeEndDate) {
        ctx.pinia.state.value.reportingStore.endingContract.complementaryInfos.notice.noticeEndDate = new Date(
          ctx.pinia.state.value.reportingStore.endingContract.complementaryInfos.notice.noticeEndDate,
        )
      }
    },
  },
})
